var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('Storage') + ': ' + _vm.storageSize + ' / ' + _vm.storageSizeQuota + ' GB',"icon":"mdi-server","filters":_vm.filters},model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}},[_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.itemsFiltered,"headers":_vm.headers,"search":_vm.$store.state.settings.filtersData.search,"dense":"","row-action":_vm.openDialog},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_c('v-icon',{attrs:{"color":_vm.wsATTENTION}},[_vm._v(_vm._s(_vm.getIcon(item.mime)))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('h5',[_vm._v(_vm._s(_vm.PARSE_FILE_SIZE(item.size)))])])]}},{key:"item.relations_count",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.relations_count > 0)?_c('h5',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.relations_count))]):_vm._e()])]}},{key:"item.date_added",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.PARSE_DATE(item.date_added)))])]}}])}),_c('wsDialog',{attrs:{"title":_vm.$t('FileDetails'),"show-delete":"","width":"700"},on:{"save":function($event){return _vm.editFile()},"delete":_vm.deleteFile},model:{value:(_vm.displayDeleteDialog),callback:function ($$v) {_vm.displayDeleteDialog=$$v},expression:"displayDeleteDialog"}},[_c('ws-text-field',{attrs:{"label":_vm.$t('Name'),"placeholder":_vm.$t('FileName')},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.selectedFile.ext !== _vm.selectedFile.name)?_c('h5',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.selectedFile.ext))]):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedFile.name),callback:function ($$v) {_vm.$set(_vm.selectedFile, "name", $$v)},expression:"selectedFile.name"}}),_c('h5',{staticClass:"font-weight-medium mt-5",style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(_vm.$t('FileInfo'))+": ")]),_c('h5',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t('FileSize'))+" : "+_vm._s(_vm.PARSE_FILE_SIZE(_vm.selectedFile.size)))]),_c('h5',[_vm._v(" "+_vm._s(_vm.$t('FileType'))+" : "+_vm._s(_vm.getFileType(_vm.selectedFile.mime)))]),_c('h5',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.$t('FileRelations'))+" : "+_vm._s(_vm.selectedFile.relations_count))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }