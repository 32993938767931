<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Storage') + ': ' + storageSize + ' / ' + storageSizeQuota + ' GB' "
      icon="mdi-server"
      :filters="filters"
    >

<!--    <v-sheet class="d-flex justify-space-between wsRoundedLight mt-3 pa-2" dark :color="wsATTENTION">-->
<!--      <v-icon class="mr-2">mdi-warning</v-icon>-->
<!--      <h5> Сховище переповнене ({{ storageSize + ' / ' + storageSizeQuota }} GB), ви не зможете завантажувати файли, поки не звільните місце в рамках вашої квоти. також ви можете змінити ваш тарифний план</h5>-->
<!--     <v-icon v-ripple :class="pointer" small>mdi-close</v-icon>-->
<!--    </v-sheet>-->

    <ws-data-table
        class="mt-5"
        :items="itemsFiltered"
        :headers="headers"
        :search="$store.state.settings.filtersData.search"
        dense
        :row-action="openDialog"
    >

      <template #item.name="{item}">
        <h5>
          <v-icon :color="wsATTENTION">{{ getIcon(item.mime) }}</v-icon>
          {{ item.name }}
        </h5>
      </template>

      <template #item.size="{item}">
       <div>
         <h5>{{ PARSE_FILE_SIZE(item.size)    }}</h5>
       </div>
      </template>

      <template #item.relations_count="{item}">
        <div>
          <h5 class="text-center" v-if="item.relations_count > 0"> {{ item.relations_count   }}</h5>
        </div>
      </template>
      <template #item.date_added="{item}">
        <h5 class="font-weight-medium"> {{ PARSE_DATE(item.date_added) }}</h5>
      </template>

    </ws-data-table>


    <wsDialog
        :title="$t('FileDetails')"
        v-model="displayDeleteDialog"
        @save="editFile()"
        @delete="deleteFile"
        show-delete
        width="700"
    >
      <ws-text-field
          v-model="selectedFile.name"
          :label="$t('Name')"
          :placeholder="$t('FileName')"
      >
        <template #append>
          <h5 class="mt-1" v-if="selectedFile.ext !== selectedFile.name">{{ selectedFile.ext }}</h5>
        </template>
      </ws-text-field>

      <h5 class="font-weight-medium mt-5" :style="`color : ${wsACCENT}`"> {{ $t('FileInfo') }}: </h5>
      <h5 class="mt-3" > {{ $t('FileSize') }} : {{ PARSE_FILE_SIZE(selectedFile.size)  }}</h5>
      <h5 > {{ $t('FileType') }} : {{ getFileType(selectedFile.mime) }}</h5>
      <h5 class="mb-5" > {{ $t('FileRelations') }} : {{ selectedFile.relations_count }}</h5>


    </wsDialog>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "settingsStorage",
  data() {
    return {
      displayDeleteDialog : false,
      selectedFile : {},
      items : [],
      storageSize : 0,
      storageSizeQuota : 0,
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('FileRelations') , value : 'relations_count' , width : 10 },
        { text : this.$t('DateCreated') , value : 'date_added' },
        { text : this.$t('FileSize') , value : 'size' },
      ]
    },
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if (this.$store.state.settings.filtersData.relations) {
        items = items.filter( el => parseInt(el[this.$store.state.settings.filtersData.relations]) === 1 )
      }

      return items

    },
    filters() {
      return [
          { text : this.$t('FileRelations')  , value : 'relations' , type : 'select' , items : [
              { text : this.$t('CourseContent') , value : 'course_content' },
              { text : this.$t('CourseDescription') , value : 'course_description' },
              { text : this.$t('CourseImage') , value : 'course_image' },
              { text : this.$t('StudentReply') , value : 'student_reply' },
              { text : this.$t('QuestionBank') , value : 'question_bank' },
              { text : this.$t('TeacherReply') , value : 'teacher_reply' },
            ] },
      ]
    }
  },
  methods : {
    ...mapActions('storage', ['GET_STORAGE_INFO' , 'DELETE_FILE' , 'EDIT_FILE_NAME']),

    async deleteFile() {

      let result = await this.DELETE_FILE(this.selectedFile.uuid)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el=>el.uuid === this.selectedFile.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index,1)
      this.displayDeleteDialog = false

      this.storageSize = (this.storageSize - (this.selectedFile.size/1000/1000/1000) ).toFixed(2)
      this.notify(this.$t('FileDeleted'))

    },
    editFile() {

      let result = this.EDIT_FILE_NAME(this.selectedFile)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el=>el.uuid === this.selectedFile.uuid )
      if ( index === -1 ) {
        return
      }

      this.items[index].name= this.selectedFile.name + '.' + this.selectedFile.ext
      this.displayDeleteDialog = false


    },
    openDialog(file) {
      let expl = file.name.split('.')
      let ext = expl[expl.length -1]
      expl.splice(-1,1);
      let name = expl.join('.')
      this.selectedFile = JSON.parse(JSON.stringify(file))
      this.selectedFile.ext = ext
      this.selectedFile.name = name
      this.displayDeleteDialog = true
    },

    getIcon(mime) {

      if (!mime ) {
        return 'mdi-file-outline'
      }

      if ( mime.includes('image') ) {
        return 'mdi-file-image-outline'
      }

      if ( mime.includes('video') ) {
        return 'mdi-file-video-outline'
      }

      return 'mdi-file-outline'

    },
    getFileType(mime) {
      if (!mime ) {
        return this.$t('File')
      }

      if ( mime.includes('image') ) {
        return this.$t('Image')
      }

      if ( mime.includes('video') ) {
        return this.$t('Video')
      }

      return this.$t('File')

    },

    async initPage() {
      let result = await this.GET_STORAGE_INFO()
      if ( !result || result === true) {
        return
      }
      result.files.reverse()
      this.items = result.files
      this.storageSize  = result.storage_size
      this.storageSizeQuota  = result.storage_size_quota
    }
  },
  mounted() {
    this.initPage()
    this.$store.state.settings.filters = this.filters
  },
  beforeDestroy() {
    this.$store.state.settings.filters = []
  }
}
</script>

<style scoped>

</style>